module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"heapsgo","short_name":"heapsgo","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/assets/favicons/ritta.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f0258931e3a37e9d0ba3d8463498d00b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2h9CBosi0eZnCK1Jj5tE5krfKaGa6hC0","devKey":"8ZP7MzUwIjtwMNICyKbuFIOF50MuVe4A","trackPage":true,"delayLoad":true,"manualLoad":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","da","no","is"],"defaultLanguage":"en","siteUrl":"https://ritta.heapsgo.com","trailingSlash":"always","generateDefaultLanguagePage":true,"i18nextOptions":{"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5PTXHKVT","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
